import React from "react";
import Swal from "sweetalert2";
import { Drawer } from "@material-ui/core";

import AddArtist from "./AddArtist";
import Loader from "../../common/Loader/Loader";

import { axiosInstance } from "../../../utility/api";
import { capitalizeFirstLetter, formatAPIError } from "../../../utility/helper";
import API from '../../../APIActions/api'

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  title: {
    width: "90%",
    marginBottom: "20px",
  },
  productCard: {
    boxShadow: "0px 0px 2px #bbb",
    padding: "10px",
    marginBottom: "20px",
  },
};

export default class ArtistUiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      artistName: "",
      description: '',
      error: {
        status: false,
        message: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      isEdit: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedRow &&
      this.state.id !== this.props.selectedRow.id
    ) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      // this.setState({
      //   ...this.state,
      //   isEdit: false,
      // });
    }
  }

  componentDidMount() {
    if (this?.props?.selectedRow) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    if(name === 'description') {
      if (value.length <= 250) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      } 
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });

    this.setShowLoader(true, "Please Wait.");
    const payload = { 
        artistName: this.state.artistName,
        description: this.state.description
    };
    if (!this.state.isEdit) {
        API.addArtist(payload)
        .then((res) => {
            console.log('res of addArtist', res)
            this.setShowLoader(false, "");
            this.setState({
                showLoader: false,
                isDrawerOpened: false,
                artistName: "",
                description: "",
                error: {
                    status: false,
                    message: "",
                },
            });
            this.props.onClose();
            Swal.fire("Success!", capitalizeFirstLetter(res?.data?.message) || 'Artist successfully created', "success");
            window.location.reload(true);
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          let errMsg = formatAPIError(err)
          Swal.fire("Alert!", errMsg, "error");
        });
    } else {
      this.handleEditForm(e);
      this.setShowLoader(false, "");
    }
  };

  handleEditForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    this.setShowLoader(true, "Please Wait");
    const payload = { 
        artistName: this.state.artistName,
        description: this.state.description
    };
    API.updateArtist(this.state.id, payload)
        .then((res) => {
            console.log('res of update artist', res)
            this.props.onClose();
            this.setShowLoader(false, "");
            Swal.fire("Success!",capitalizeFirstLetter(res?.data?.message) || 'Artist successfully updated', "success");
            window.location.reload(true);
        })
        .catch((err) => {
            this.setShowLoader(false, "");
            console.log('error', err)
            let errMsg = formatAPIError(err)
            Swal.fire("Alert!", errMsg, "error");
        });
  };

  render() {
    const isDrawerOpened = this.props.open;
    return (
      <div>
        <Drawer
          anchor="right"
          variant="temporary"
          open={isDrawerOpened}
          onClose={this.props.onClose}
        >
          {this.state.showLoader.open == true && (
            <Loader loaderInfo={this.state.showLoader.message} />
          )}
          <AddArtist
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            handleChange={this.handleChange}
            handleSubmitForm={this.handleSubmitForm}
            isEdit={this.state.isEdit}
          />
        </Drawer>
      </div>
    );
  }
}
